import { createSlice, createAction } from "@reduxjs/toolkit";

export interface snackBarState {
  visible: boolean;
  message: string;
}

const initialState: snackBarState = {
  visible: false,
  message: "",
};

export const showMessage = createAction("snackBar/show", (message: string) => {
  return { payload: { message } };
});

export const hideMessage = createAction("snackBar/hide");

export const snackBarSlice = createSlice({
  name: "snackBar",
  initialState,
  reducers: {
    show: (state, { payload }) => {
      state.visible = true;
      state.message = payload.message;
    },
    hide: (state) => {
      state.visible = false;
      state.message = "";
    },
  },
});

export default snackBarSlice.reducer;
