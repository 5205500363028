import { DEVELOP } from './config.dev'
import { AppConfig } from './types'

const env = process.env.NODE_ENV === 'development' ? 'development' : 'production'

export const isProduction = env === 'production'

const getConfig = (): AppConfig => {
  if (env === 'production') {
    return {
      apiEndpoint: process.env.REACT_APP_API_ENDPOINT || '',
      webAuthToken: process.env.REACT_APP_WEB_AUTH_TOKEN || '',
      firebaseConfig: process.env.REACT_APP_FIREBASE_CONFIG || DEVELOP.firebaseConfig,
    }
  } else {
    return DEVELOP
  }
}

export default getConfig()
