import * as React from 'react'
import Box from '@mui/material/Box'
import { Divider, Grid, Link, styled, Typography } from '@mui/material'
import Text from 'components/Text'
import topBackground from 'assets/images/top_background.png'
import { analyticsLog } from '../../firebase'

const HeaderTop = styled(Box)(({ theme }) => ({
  height: 81,
  width: '100%',
  backgroundImage: `url(${topBackground})`,
  backgroundRepeat: 'no-repeat',
  backgroundColor: '#194052',
}))

const HeaderMenuGrid = styled(Grid)(({ theme }) => ({
  padding: '24px 32px',
}))

const Menu = styled(Box)(({ theme }) => ({
  flexDirection: 'row',
  display: 'flex',
  height: 40,
  justifyContent: 'flex-end',
  alignItems: 'center',
  [theme.breakpoints.down('md')]: {
    alignItems: 'flex-start',
    height: 'auto',
    marginTop: theme.spacing(2),
    flexDirection: 'column',
  },
  '.item': {
    color: '#194052',
    textDecoration: 'underline',
    fontSize: 18,
    lineHeight: '16px',
    marginLeft: 20,
    fontWeight: '457',
    fontFamily: 'SF Compact',
    cursor: 'pointer',
    [theme.breakpoints.down('md')]: {
      marginBottom: 16,
      marginLeft: 0,
    },
  },
  '.active': {
    fontSize: 18,
    color: '#194052',
    fontWeight: 'bold',
  },
}))

const Separator = styled(Divider)(({ theme }) => ({
  backgroundColor: '#E3E3E3',
  margin: '0px 32px',
}))

interface Props {
  title?: string
  page?: 'GetYourDeviceReady' | 'ContactUs'
}

const Header = ({ title = 'Kingfisher Trade-In', page }: Props) => {
  const goToTelstra = () => {
    analyticsLog('Screen_Customer website_how trade in works')
    window.open('https://www.telstra.com.au/trade-in', '_blank')
  }

  return (
    <>
      <HeaderTop aria-label="Kingfisher logo" tabIndex={1} />
      <HeaderMenuGrid container>
        <Grid item xs={12} sm={12} md={4}>
          <Text tabIndex={1} variant="heading-1">
            {title}
          </Text>
        </Grid>
        <Grid item xs={12} sm={12} md={8}>
          <Menu>
            <Link onClick={goToTelstra}>
              <Typography tabIndex={2} className="item">
                How Trade-In Works
              </Typography>
            </Link>
            <Link href="/get-your-device-ready">
              <Typography tabIndex={2} className={page === 'GetYourDeviceReady' ? 'item active' : 'item'}>
                Get Your Device Ready
              </Typography>
            </Link>
            <Link href="/contact-us">
              <Typography tabIndex={2} className={page === 'ContactUs' ? 'item active' : 'item'}>
                Contact Us
              </Typography>
            </Link>
          </Menu>
        </Grid>
      </HeaderMenuGrid>
      <Separator />
    </>
  )
}

export default Header
