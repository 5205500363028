import { configureStore } from '@reduxjs/toolkit'
import { createLogger } from 'redux-logger'
import snackReducer from './redux/snackBar/snackBarSlice'
import mockReducer from './redux/mock/mockSlice'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import { combineReducers } from 'redux'

const persistConfig = {
  key: 'root',
  storage,
}
const rootReducer = combineReducers({
  snackBar: snackReducer,
  mock: mockReducer,
})
const persistedReducer = persistReducer(persistConfig, rootReducer)

const logger = createLogger({
  duration: true,
  level: 'info',
  diff: true,
})

export const store = configureStore({
  reducer: persistedReducer,
  //   middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
  middleware: process.env.NODE_ENV === 'development' ? [logger] : [],
})

export const persistor = persistStore(store)

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
