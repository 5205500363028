export const queryWithTimeout = async (fn: any, timeout = 10000) => {
  return Promise.race([fn, new Promise((_, reject) => setTimeout(() => reject(new Error('timeout')), timeout))])
}

export const sortObjectArrayByKey = (array: any[], key: string, order: 'asc' | 'desc' = 'asc') => {
  let result = []
  for (let i = 0; i < array.length; i++) {
    result.push(array[i])
  }
  result.sort(function (a, b) {
    const keyA = a[key]
    const keyB = b[key]
    if (order === 'asc') {
      return keyA > keyB ? 1 : -1
    } else if (order === 'desc') {
      return keyA > keyB ? -1 : 1
    }
    return 0
  })
  return result
}

export const removeStrEndSlash = (str: string) => {
  return str.slice(-1) === '/' ? str.slice(0, -1) : str
}

export const addDollarBeforePrice = (price: string | number) => {
  let text = price.toString()
  return text.charAt(0) === '$' ? text : '$' + text
}

export const strStatusConvert = (status: string, allUppercase?: boolean) => {
  const chars = status.split('_')
  let str = []
  for (let i = 0; i < chars.length; i++) {
    const lowerStr = chars[i].toLowerCase()
    const capitalStr = lowerStr.charAt(0).toUpperCase() + lowerStr.slice(1)
    const word = (i !== 0 && !allUppercase) || lowerStr === 'in' ? lowerStr : capitalStr
    str.push(word)
  }
  return str.join(' ')
}

const Mapping = [
  { key: 'transactionSubmitted', source: ['TRANSACTION_SUBMITTED', 'Transaction Submitted'], label: 'Transaction Submitted' },
  { key: 'deviceLodged', source: ['Recovery Initiated', 'Device Lodged'], label: 'Device Lodged' },
  { key: 'creditInProgress', source: ['Credit in Progress', 'Credit Initiated'], label: 'Credit in Progress' },
  { key: 'creditSuccessful', source: ['Credit Successful'], label: 'Credit Successful' },
  { key: 'creditUnSuccessful', source: ['Credit Unsuccessful'], label: 'Credit Unsuccessful' },
  { key: 'creditFailed', source: ['Credit Failed'], label: 'Credit Failed' },
  { key: 'creditExpired', source: ['Transaction_Aged', 'TRANSACTION_AGED'], label: 'Transaction Expired' },
  { key: 'creditCancelled', source: ['Transaction Cancelled'], label: 'Transaction Cancelled' },
  { key: 'creditClosed', source: ['Closed', 'closed', 'CLOSED'], label: 'Transaction Closed' },
]

export type mapType =
  | 'transactionSubmitted'
  | 'deviceLodged'
  | 'creditInProgress'
  | 'creditSuccessful'
  | 'creditUnSuccessful'
  | 'creditFailed'
  | 'creditExpired'
  | 'creditCancelled'

export const getStatus = (allStatus: any[], key: mapType) => {
  let sources: string[] = []
  let label = ''
  let sortedStatus = sortObjectArrayByKey(allStatus, 'statusDate', 'desc')

  for (let i = 0; i < Mapping.length; i++) {
    if (Mapping[i].key === key) {
      sources = Mapping[i].source
      label = Mapping[i].label
    }
  }

  for (let i = 0; i < sortedStatus.length; i++) {
    if (sources.includes(sortedStatus[i].status)) {
      return { isFinished: true, date: sortedStatus[i].statusDate, label }
    }
  }
  return { isFinished: false, label }
}

export const getLatestStatus = (allStatus: any[]) => {
  let label = ''
  let sortedStatus = sortObjectArrayByKey(allStatus, 'statusDate', 'desc')

  for (let i = 0; i < sortedStatus.length; i++) {
    for (let j = 0; j < Mapping.length; j++) {
      if (Mapping[j].source.includes(sortedStatus[i].status)) {
        return { key: Mapping[j].key, isFinished: true, date: sortedStatus[i].statusDate, label: Mapping[j].label }
      }
    }
  }
  return { isFinished: false, label }
}

const deviceStr = (transaction: any) => {
  let str = []
  const { make, model, size, capacity, caseType, type } = transaction
  if (make && make !== 'NO') str.push(make)
  if (model && model !== 'NO') str.push(model)
  if (type === 'Mobiles') {
    if (capacity && capacity !== 'NO') str.push(capacity)
  } else {
    if (caseType && caseType !== 'NO') str.push(caseType)
    if (size && size !== 'NO') str.push(size)
  }
  return str.join('_')
}

export const convertApiTransaction = (transaction: any) => {
  const {
    id,
    validationServiceNumber,
    returnMethod,
    actualReturnMethod,
    gradeGrouping,
    price,
    conNote,
    customerStatus,
    warehouseDeviceReturnStatus,
    warehouseGrade,
    imeiSerial,
    dcmCase,
    dateSubmitted,
    storeName,
    statusDetails,
  } = transaction

  return {
    transactionId: id,
    dcmCase,
    validationServiceNumber,
    imei: imeiSerial,
    transactionDate: dateSubmitted,
    transactionGrade: gradeGrouping,
    returnMethod: returnMethod,
    actualReturnMethod: actualReturnMethod,
    storeName: storeName,
    deviceAssessed: deviceStr(transaction),
    tradeInCredit: price,
    conNote: conNote,
    customerStatus: customerStatus,
    warehouseStatus: warehouseDeviceReturnStatus,
    warehouseGrade: warehouseGrade,
    statusDetails,
  }
}

export const convertPrice = (price: string) => {
  const floatPrice = parseFloat(price)
  return isNaN(floatPrice) ? price : floatPrice
}
