import { AppConfig } from './types'

export const DEVELOP: AppConfig = {
  apiEndpoint: 'https://develop.kingfisher-mx.com',
  webAuthToken: 'MJaIGXGkWHdXJSxv9M4MOoA1qCbGmezY2GRH8aLjgv9gleoUzN',
  firebaseConfig: {
    apiKey: 'AIzaSyCMWxhd13AU-SEgW7EMnuNGGNuO2AB7jUM',
    authDomain: 'integral-kit-359900.firebaseapp.com',
    projectId: 'integral-kit-359900',
    storageBucket: 'integral-kit-359900.appspot.com',
    messagingSenderId: '462683581564',
    appId: '1:462683581564:web:cdc7299a6241bcb0a9edf9',
    measurementId: 'G-NX55HBC4KL',
  },
}
