import * as React from 'react'
import Box from '@mui/material/Box'
import Text from 'components/Text'
import { Grid, Modal } from '@mui/material'
const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  // border: "2px solid #000",
  // boxShadow: 20,
  borderRadius: '20px',
  p: 4,
}
const Error = ({ error, resetErrorBoundary }: any) => {
  return (
    <Box sx={{ minWidth: 275 }}>
      <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center" sx={{ minHeight: '100vh' }}>
        <Modal
          open={true}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          BackdropProps={{
            sx: { backgroundColor: 'rgba(190, 197, 205, 0.7)' },
          }}>
          <Box sx={style}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}>
              <Text variant="heading-1">404</Text>
              <Text variant="heading-3" sx={{ marginTop: '10px', textAlign: 'center' }}>
                Page Not Found
              </Text>
            </Box>
          </Box>
        </Modal>
      </Grid>
    </Box>
  )
}

export default Error
