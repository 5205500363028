import * as React from 'react'
import Box from '@mui/material/Box'
import Text from 'components/Text'
import { Button, Grid, Modal } from '@mui/material'
const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  borderRadius: '20px',
  p: 4,
}
const Error = ({ error, resetErrorBoundary }: any) => {
  return (
    <Box sx={{ minWidth: 275 }}>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        sx={{ minHeight: '100vh' }}>
        <Modal
          open={true}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          BackdropProps={{
            sx: { backgroundColor: 'rgba(190, 197, 205, 0.7)' },
          }}>
          <Box sx={style}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                // justifyContent: "center",
              }}>
              <Text variant="heading-1">Something went wrong!</Text>
              <Text
                variant="heading-3"
                sx={{ marginTop: '10px', textAlign: 'center' }}>
                Try refreshing the page or try again later
              </Text>
              {/* <Text variant="heading-1">{error.message}</Text> */}
              <Button
                variant="contained"
                sx={{
                  borderRadius: '20px',
                  textAlign: 'center',
                  paddingLeft: '30px',
                  paddingRight: '30px',
                  marginTop: '40px',
                  textTransform: 'none',
                  fontWeight: 'bold',
                }}
                onClick={resetErrorBoundary}>
                Refresh Page
              </Button>
            </Box>
          </Box>
        </Modal>
      </Grid>
    </Box>
  )
}

export default Error
