import { faker } from '@faker-js/faker'
import { Admin, Customer } from '../redux/mock/mockSlice'

const data = [
  { name: 'Transaction ID', value: '10501' },
  { name: 'DCM Case', value: '33243' },
  { name: 'Return', value: 'Return Label' },
  { name: 'Return Method Details', value: '242394329' },
  { name: 'Device Assessed', value: 'Apple iPhone 12 + 64GB Black' },
  { name: 'Trade-In Credit', value: '$450' },
  { name: 'Status', value: 'Pending Credit' },
]
export const agentData = [
  ['10501', '33243', 'Return Label', '242394329', 'Apple iPhone 12 + 64GB Black', '$450', 'Pending Credit'],
  ['10502', '1233', 'Drop Off', '323922', 'Apple iPhone 13 + 128GB White', '$250', 'Credited'],
]
export default data

export const generateCustomerData = (count: number) => {
  let result: Customer[] = []
  for (let i = 0; i < count; i++) {
    const step = faker.datatype.number({ min: 1, max: 4 })
    result.push({
      firstName: faker.name.firstName(),
      progress: [
        {
          status: 'Transaction submitted',
          finished: true,
          date: faker.date.between('2022-05-01T00:00:00.000Z', '2022-06-01T00:00:00.000Z'),
        },
        {
          status: 'Device handed off',
          finished: step > 1,
          date: step > 1 ? faker.date.between('2022-06-01T00:00:00.000Z', '2022-07-01T00:00:00.000Z') : '',
        },
        {
          status: 'Credit initiated',
          finished: step > 2,
          date: step > 2 ? faker.date.between('2022-07-01T00:00:00.000Z', '2022-08-01T00:00:00.000Z') : '',
        },
        {
          status: 'Credit applied',
          finished: step > 3,
          date: step > 3 ? faker.date.between('2022-08-01T00:00:00.000Z', '2022-09-01T00:00:00.000Z') : '',
        },
      ],
      transactionId: (10000 + i).toString(),
      dcmCase: faker.datatype.number({ min: 10000, max: 99999 }).toString(),
      returnMethod: faker.helpers.arrayElement(['returnLabel', 'dropOff']),
      returnMethodDetail: faker.helpers.arrayElement(['Chadstore store', 'Westfield store']),
      deviceAssessed:
        faker.helpers.arrayElement([
          'Apple iPhone ' + faker.datatype.number({ min: 8, max: 13 }),
          'Samsung S' + faker.datatype.number({ min: 20, max: 23 }),
        ]) +
        faker.helpers.arrayElement([' 64GB', ' 128GB']) +
        faker.helpers.arrayElement([' Black', ' White']),
      tradeInCredit: faker.datatype.number({ min: 100, max: 500 }),
      status: faker.helpers.arrayElement(['Pending Credit', 'Credited']),
    })
  }
  return result
}
const customerStatus = (step: number) => {
  if (step === 1) {
    return 'Transaction submitted'
  }
  if (step === 2) {
    return 'Device handed off'
  }
  if (step === 3) {
    return 'Credit initiated'
  }
  if (step === 4) {
    return 'Credit applied'
  }
  return ''
}

export const generateAgentData = (count: number) => {
  let result: Admin[] = []
  for (let i = 0; i < count; i++) {
    const step = faker.datatype.number({ min: 1, max: 4 })
    // let date1 = new Date()
    // let date2 = new Date()
    // let date3 = new Date()
    // let date4 = new Date()
    // let date5 = new Date()
    // date1.setHours(8, 0, 0, 0)
    // date2.setHours(12, 0, 0, 0)
    // date3.setHours(15, 0, 0, 0)
    // date4.setHours(18, 0, 0, 0)
    // date5.setHours(20, 0, 0, 0)

    let date1 = '2022-05-01T00:00:00.000Z'
    let date2 = '2022-06-01T00:00:00.000Z'
    let date3 = '2022-07-01T00:00:00.000Z'
    let date4 = '2022-08-01T00:00:00.000Z'
    let date5 = '2022-09-01T00:00:00.000Z'

    const array = [
      faker.date.between(date1, date2),
      faker.date.between(date2, date3),
      faker.date.between(date3, date4),
      faker.date.between(date4, date5),
    ].reverse()

    const transactionDate = array[step - 1]

    result.push({
      transactionId: (10000 + i).toString(),
      progress: [
        {
          status: 'Transaction submitted',
          finished: true,
          // date: faker.date.between('2022-05-01T00:00:00.000Z', '2022-06-01T00:00:00.000Z'),
          date: transactionDate,
        },
        {
          status: 'Device handed off',
          finished: step > 1,
          date: step > 1 ? array[step - 2] : '',
        },
        {
          status: 'Credit initiated',
          finished: step > 2,
          date: step > 2 ? array[step - 3] : '',
        },
        {
          status: 'Credit applied',
          finished: step > 3,
          date: step > 3 ? array[step - 4] : '',
        },
      ],
      dcmCase: faker.datatype.number({ min: 10000, max: 99999 }).toString(),
      firstName: faker.name.firstName(),
      surname: faker.name.lastName(),
      mobile:
        '04' +
        faker.datatype
          .number({
            min: 10000000,
            max: 99999999,
          })
          .toString(),
      email: faker.internet.email(),
      address: faker.address.streetAddress(),
      validationServiceNumber: faker.datatype
        .number({
          min: 10000,
          max: 99999,
        })
        .toString(),
      validationAccountNumber: faker.datatype
        .number({
          min: 10000,
          max: 99999,
        })
        .toString(),
      imei: faker.phone.imei(),
      transactionDate,
      transactionGrade: faker.helpers.arrayElement(['A', 'B', 'C', 'D']),
      returnMethod: faker.helpers.arrayElement(['returnLabel', 'dropOff']),
      returnMethodDetail: faker.helpers.arrayElement(['Chadstore store', 'Westfield store']),
      actualReturnMethod: faker.helpers.arrayElement(['Return Label', 'Drop Off']),
      storeName: faker.helpers.arrayElement(['Chadstore store', 'Westfield store']),
      deviceAssessed:
        faker.helpers.arrayElement([
          'Apple iPhone ' + faker.datatype.number({ min: 8, max: 13 }),
          'Samsung S' + faker.datatype.number({ min: 20, max: 23 }),
        ]) +
        faker.helpers.arrayElement([' 64GB', ' 128GB']) +
        faker.helpers.arrayElement([' Black', ' White']),
      tradeInCredit: faker.datatype.number({ min: 100, max: 500 }),
      topUp: faker.datatype.number({ min: 100, max: 500 }),
      conNote: faker.datatype.number({ min: 10000, max: 99999 }).toString(),
      // customerStatus: faker.helpers.arrayElement([
      //   "Pending Credit",
      //   "Credited",
      // ]),
      customerStatus: customerStatus(step),
      customerTopUpStatus: customerStatus(step),
      warehouseStatus: faker.helpers.arrayElement(['Received', 'Pending']),
      warehouseGrade: faker.helpers.arrayElement(['A', 'B', 'C', 'D']),
      note: faker.lorem.sentence(),
      // note: "",
    })
  }
  return result
}
