import * as React from 'react'
import Box from '@mui/material/Box'
import { styled } from '@mui/material'
import Header from 'pages/common/Header'

const Page = styled(Box)(({ theme }) => ({
  width: '100%',
  '.content': {
    padding: '24px 32px',
  },
}))

const Container = ({ children, headerTitle, page, sx }: any) => {
  return (
    <Box sx={sx}>
      <Header title={headerTitle} page={page} />
      <Page>{children}</Page>
    </Box>
  )
}

export default Container
