import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { BrowserRouter } from 'react-router-dom'
import { ErrorBoundary } from 'react-error-boundary'
import { QueryClient, QueryClientProvider } from 'react-query'
import { persistor, store } from './store'
import { Provider as ReduxProvider } from 'react-redux'
import Error from './pages/Error'
// import SnackBar from './components/Snackbar'
import { PersistGate } from 'redux-persist/integration/react'
import '@aws-amplify/ui-react/styles.css'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

console.log = () => {}
console.error = () => {}
console.debug = () => {}

const theme = createTheme({})
const queryClient = new QueryClient()

const myErrorHandler = (error: Error, info: { componentStack: string }) => {
  // Do something with the error
}

root.render(
  // <React.StrictMode>
  <ErrorBoundary onError={myErrorHandler} FallbackComponent={Error}>
    <ReduxProvider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <QueryClientProvider client={queryClient}>
            <ThemeProvider theme={theme}>
              <App />
              {/*<SnackBar />*/}
            </ThemeProvider>
          </QueryClientProvider>
        </BrowserRouter>
      </PersistGate>
    </ReduxProvider>
  </ErrorBoundary>,
  // </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
