import * as React from 'react'
import { Box, Grid, Skeleton } from '@mui/material'

const Table = () => {
  const renderSkeleton = () => {
    return [...Array(6)].map((x, i) => (
      <Grid key={i} item xs={2} sm={2} md={2}>
        <Skeleton
          variant="rectangular"
          sx={{ margin: '5px', height: '40px', boxSizing: 'border-box' }}
        />
      </Grid>
    ))
  }
  return (
    <Box sx={{ width: '100%' }}>
      <Grid className="content" container>
        <Grid item sm={12}>
          <Skeleton
            variant="rectangular"
            sx={{
              width: '100%',
              maxWidth: 200,
              height: '40px',
              boxSizing: 'border-box',
            }}
          />
        </Grid>
        <Grid item sm={12}>
          <br />
          <br />
          <Skeleton
            variant="rectangular"
            sx={{
              width: '100%',
              maxWidth: 400,
              height: '40px',
              boxSizing: 'border-box',
            }}
          />
        </Grid>

        <Grid tabIndex={4} item xs={12} sm={12} lg={3}>
          <Skeleton
            variant="rectangular"
            sx={{
              width: '100%',
              maxWidth: 300,
              marginTop: '70px',
              height: '60px',
              boxSizing: 'border-box',
            }}
          />
        </Grid>
        <Grid tabIndex={4} item xs={12} sm={12} lg={3}>
          <Skeleton
            variant="rectangular"
            sx={{
              width: '100%',
              maxWidth: 300,
              marginTop: '70px',
              height: '60px',
              boxSizing: 'border-box',
            }}
          />
        </Grid>
        <Grid tabIndex={4} item xs={12} sm={12} lg={3}>
          <Skeleton
            variant="rectangular"
            sx={{
              width: '100%',
              maxWidth: 300,
              marginTop: '70px',
              height: '60px',
              boxSizing: 'border-box',
            }}
          />
        </Grid>
        <Grid tabIndex={4} item xs={12} sm={12} lg={3}>
          <Skeleton
            variant="rectangular"
            sx={{
              width: '100%',
              maxWidth: 300,
              marginTop: '70px',
              height: '60px',
              boxSizing: 'border-box',
            }}
          />
        </Grid>
        <Grid item sm={12} sx={{ marginTop: '40px' }}>
          <Skeleton
            variant="rectangular"
            sx={{
              width: '100%',
              maxWidth: '220px',
              height: '40px',
              boxSizing: 'border-box',
            }}
          />
          <Skeleton
            variant="rectangular"
            sx={{
              height: '40px',
              boxSizing: 'border-box',
              marginTop: '30px',
            }}
          />
          <Grid sx={{ width: '100%', marginTop: '10px' }} container>
            {renderSkeleton()}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}

export default Table
