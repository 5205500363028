import { Box, styled, Typography } from '@mui/material'
import * as React from 'react'
import Container from './common/Container'
import Text from 'components/Text'

const StyledBox = styled(Box)(({ theme }) => ({
  padding: '30px 62px',
  marginTop: '40px',
  backgroundColor: '#F8F8F8',
}))

const Title = styled(Typography)(({ theme }) => ({
  fontSize: '22px',
  lineHeight: '29px',
  color: '#194052',
  margin: '10px 0px 10px -30px',
  fontFamily: 'SF Compact bold',
}))

const GetYourDeviceReady = () => {
  const list1 = [
    'Make sure you have another device handy that you can use when you send in your old device.',
    'Back up your contacts, photos, messages, music and anything else you want to keep.',
    'Make sure any location or locking features are turned off.',
    'Turn off Find My iPhone or Google Account Lock.',
    "Take out your device's SIM card.",
    'Reset your device to its factory settings.',
    'Power off your device.',
  ]

  const list2 = [
    'If you paired an Apple Watch with your iPhone, unpair your Apple Watch.',
    'Back up your device.',
    'Sign out of iCloud and the iTunes & App Store.',
    "If you're using iOS 10.3 or later, tap Settings > [your name]. Scroll down and tap Sign Out. Enter your Apple ID password and tap Turn Off.",
    "If you're using iOS 10.2 or earlier, tap Settings > iCloud > Sign Out. Tap Sign Out again, then tap Delete from My [device] and enter your Apple ID password. Then go to Settings > iTunes & App Store > Apple ID > Sign Out. Be sure to check and turn off Find My [device].",
    'Go to Settings and tap General > Reset > Erase All Content and Settings. If you turned on Find My [device], you might need to enter your Apple ID and password.',
    'If asked for your device passcode or Restrictions passcode, enter it. Then tap Erase [device].',
  ]

  const list3 = [
    'Back up your device.',
    'Sign out of your accounts including Google and Samsung.',
    'Tap Settings > Accounts and backup > Accounts > Select Account > Tap Remove Account.',
    'Please note labels may differ across different Android devices and versions.',
    'Go to Settings and tap General Management  > Reset > Factory Data Reset > Reset.',
    'Follow the on screen prompts to complete the reset.',
  ]

  const renderList = (list: string[], bullet?: boolean) => {
    return (
      <ol
        style={
          bullet
            ? {
                marginLeft: 20,
                marginRight: 20,
                marginTop: 0,
                marginBottom: 0,
                padding: 0,
              }
            : {
                marginLeft: 5,
                margin: 0,
                padding: 0,
                // listStyleType: 'none',
              }
        }>
        {list.map((v, k) => (
          <li style={{ color: '#194052', fontFamily: 'SF Compact', fontSize: 18 }} key={k}>
            <Text variant="get-your-device-ready">{v}</Text>
          </li>
        ))}
      </ol>
    )
  }

  return (
    <Container headerTitle="Get yourself Trade-In ready" page="GetYourDeviceReady">
      <StyledBox>
        <Title>To get your device return-ready, please make sure you do the following:</Title>
        {renderList(list1, false)}
      </StyledBox>
      <StyledBox>
        <Title>How to restore factory settings on your Apple iPhone and iPad:</Title>
        {renderList(list2, false)}
      </StyledBox>
      <StyledBox>
        <Title>How to restore factory settings on your Samsung Galaxy and Google Pixel:</Title>
        {renderList(list3, false)}
      </StyledBox>
      <Box sx={{ background: '#194052', height: '165px', marginTop: '40px' }}></Box>
    </Container>
  )
}

export default GetYourDeviceReady
