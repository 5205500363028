import { createSlice, createAction } from '@reduxjs/toolkit'
import { generateAgentData, generateCustomerData } from 'api/fakeData'

export type Customer = {
  firstName: string
  progress: {
    status: string
    finished: boolean
    date: Date | ''
  }[]
  transactionId: string
  dcmCase: string
  returnMethod: 'returnLabel' | 'dropOff'
  returnMethodDetail: string
  deviceAssessed: string
  tradeInCredit: number
  status: string
}

export type Admin = {
  transactionId: string
  progress: {
    status: string
    finished: boolean
    date: Date | ''
  }[]
  dcmCase: string
  firstName: string
  surname: string
  mobile: string
  email: string
  address: string
  validationServiceNumber: string
  validationAccountNumber: string
  imei: string
  transactionDate: Date
  transactionGrade: string
  returnMethod: 'returnLabel' | 'dropOff'
  returnMethodDetail: string
  deviceAssessed: string
  actualReturnMethod: string
  storeName: string
  tradeInCredit: number
  topUp: number
  conNote: string
  customerStatus: string
  customerTopUpStatus: string
  warehouseStatus: string
  warehouseGrade: string
  note: string
}

export interface mockState {
  customer: Customer[] | null
  admin: Admin[] | null
}

const initialState: mockState = {
  customer: null,
  admin: null,
}

// export const mockInit = createAction("mock/init", (message: string) => {
//   return { payload: { message } };
// });

export const mockInit = createAction('mock/init')

// export const hideMessage = createAction("snackBar/hide");

export const mockSlice = createSlice({
  name: 'mock',
  initialState,
  reducers: {
    init: state => {
      const customer = generateCustomerData(100)
      const admin = generateAgentData(15)
      state.customer = customer
      state.admin = admin
    },
    // hide: (state) => {
    //   state.visible = false;
    //   state.message = "";
    // },
  },
})

export default mockSlice.reducer
