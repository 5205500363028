import React, { useEffect } from 'react'
import Router from './router'
import { useLocation } from 'react-router-dom'
import { analyticsLog } from './firebase'

function App() {
  const { pathname } = useLocation()

  useEffect(() => {
    analyticsLog(pathname)
  }, [pathname])

  return <Router />
}

export default App
