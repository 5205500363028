import { getTransactionById } from './../transaction/index'
import { useSelector } from 'react-redux'
import { useQuery } from 'react-query'
import { Customer } from '@redux/mock/mockSlice'

export const useTransaction = (transactionId: string | undefined, real?: boolean) => {
  const customers = useSelector((state: any) => state.mock.admin)
  return useQuery<Customer>(
    'get-transaction',
    async () => {
      if (real) {
        return await getTransactionById(transactionId)
      }
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          let result
          for (let i = 0; i < customers.length; i++) {
            if (customers[i].transactionId === transactionId?.toString()) {
              result = customers[i]
            }
          }
          return resolve(result)
        }, 1000)
      })
    },
    { enabled: false, retry: false },
  )
}
