import { Box, Grid, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import Text from 'components/Text'
import tick from 'assets/images/tick.png'
import unTick from 'assets/images/un_tick.png'
import cross from 'assets/images/aged.png'
import { useParams } from 'react-router-dom'
import { useTransaction } from 'api/hooks/useTransaction'
import moment from 'moment'
import Customer from 'components/skeleton/Customer'
import Container from 'pages/common/Container'
import { useEffect, useState } from 'react'
import {
  addDollarBeforePrice,
  convertApiTransaction,
  convertPrice,
  getLatestStatus,
  getStatus,
  mapType,
  sortObjectArrayByKey,
} from 'utils/index'
import PageSnackbar from 'components/PageSnackbar'
import { analyticsLogEvent } from '../../firebase'

const StatusGrid = styled(Grid)(({ theme }) => ({
  marginTop: 10,
  padding: '24px 32px',
  backgroundColor: '#F8F8F8',
  [theme.breakpoints.down('md')]: {
    backgroundColor: '#F8F8F8',
  },
  '.status': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    maxWidth: 280,
    marginTop: 16,
    marginBottom: 8,
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
    },
  },
  '.status-expired': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    marginTop: 16,
    marginBottom: 8,
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
    },
  },
}))

const Info = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  flexDirection: 'row',
  marginTop: 20,
  boxSizing: 'border-box',
  justifyContent: 'space-between',
  padding: '0px 32px',
  [theme.breakpoints.down('lg')]: {
    flexDirection: 'column',
    padding: 0,
  },
  '.label': {
    color: '#194052',
    fontSize: 18,
    lineHeight: '48px',
    fontWeight: '790',
    backgroundColor: '#F8F8F8',
    height: 48,
    [theme.breakpoints.down('lg')]: {
      padding: '0px 32px',
    },
  },
  '.info': {
    color: '#426575',
    fontSize: 18,
    lineHeight: '48px',
    fontWeight: '350',
    fontFamily: 'SF Compact',
    [theme.breakpoints.down('lg')]: {
      padding: '0px 32px',
    },
  },
}))

const InfoBackground = styled(Box)(({ theme }) => ({
  height: '48px',
  zIndex: -1,
  width: '100%',
  position: 'absolute',
  background: '#F8F8F8',
  [theme.breakpoints.down('lg')]: {
    display: 'none',
  },
}))

export default function Transaction() {
  const { transactionId } = useParams()
  const { data, isLoading, refetch, error } = useTransaction(transactionId, true)
  const [transaction, setTransaction] = useState<any>(null)
  const [progress, setProgress] = useState<any>([])
  const [isErrorVisible, setIsErrorVisible] = useState<boolean>(false)

  const renderItem = (data: any, index: number) => {
    return (
      <Box key={data.name}>
        <Typography tabIndex={5 + index} className="label">
          {data.name}
        </Typography>
        <Typography tabIndex={5 + index} className="info">
          {data.value}
        </Typography>
      </Box>
    )
  }

  useEffect(() => {
    setIsErrorVisible(!!error)
    if (error) {
      analyticsLogEvent('Screen_CustomerWebsite_landing_Error', {
        error_message: 'Something went wrong. Try refreshing the page or try again later',
      })
    }
  }, [error])

  useEffect(() => {
    if (data) {
      setTransaction(convertApiTransaction(data))
      //@ts-ignore
      setProgress(sortObjectArrayByKey(data.statusDetails, 'statusDate', 'asc'))
    }
  }, [data])

  useEffect(() => {
    refetch()
  }, [refetch])

  const createInfo = (data: any) => {
    const { actualReturnMethod, tradeInCredit, dcmCase, deviceAssessed } = data
    return [
      { name: 'Reference Number', value: dcmCase },
      { name: 'Return Method', value: actualReturnMethod },
      { name: 'Device Assessed', value: deviceAssessed },
      { name: 'Trade-In Credit', value: addDollarBeforePrice(convertPrice(tradeInCredit)) },
      { name: 'Status', value: getLatestStatus(progress).label },
    ]
  }

  const onErrorClose = () => {
    setIsErrorVisible(false)
  }

  const renderLastStatus = () => {
    const latestStatus = getLatestStatus(progress)
    if (latestStatus.key === 'creditExpired') {
      return createErrorStatusItem('creditExpired')
    } else if (latestStatus.key === 'creditCancelled') {
      return createErrorStatusItem('creditCancelled')
    } else if (latestStatus.key === 'creditUnSuccessful') {
      return createErrorStatusItem('creditUnSuccessful')
    } else if (latestStatus.key === 'creditFailed') {
      return createErrorStatusItem('creditFailed')
    } else {
      return createStatusItem('creditSuccessful')
    }
  }

  const createStatusItem = (key: mapType) => {
    const status = getStatus(progress, key)
    const { isFinished, date, label } = status
    return (
      <Grid key={key} tabIndex={4} item xs={12} sm={12} lg={3}>
        <Box className="status">
          <Text variant={isFinished ? 'heading-2' : 'heading-3'}>{label}</Text>
          <img style={{ width: 28, height: 28 }} src={isFinished ? tick : unTick} alt={isFinished ? 'selected' : 'not selected'} />
        </Box>
        {isFinished && <Text variant="subheading-1">{moment(date).format('DD/MM/YYYY')}</Text>}
      </Grid>
    )
  }

  const getDescription = (key: mapType) => {
    if (key === 'creditFailed') {
      return 'We’ve experienced a technical issue and your Trade-In credit to your account has failed. We will resolve this and you should see your credit appear in the next 48 hours. You don’t need to do anything. Once the credit has been applied you will see the status change to Credit Successful.'
    } else if (key === 'creditUnSuccessful') {
      return 'Your Trade-In credit has been unsuccessful. We will reattempt the credit and if required, Telstra may contact you to resolve this. You don’t need to do anything. Once the credit has been applied you will see the status change to Credit Successful.'
    } else if (key === 'creditExpired') {
      return 'Your Trade-In has now expired. The device was not received within the 21 day period. If you wish to still trade in your device, please go to the Trade-In app and re-submit your device assessment.'
    } else if (key === 'creditCancelled') {
      return 'Your Trade-In transaction has been cancelled, because an updated transaction for the same IMEI was received. You may only have one transaction open at a time per IMEI.'
    }
  }

  const createErrorStatusItem = (key: mapType) => {
    const status = getStatus(progress, key)
    const { isFinished, date, label } = status
    const description = getDescription(key)
    return (
      <Grid key={key} tabIndex={4} item xs={12} sm={12} lg={3}>
        <Box className={description ? 'status-expired' : 'status'}>
          <Text variant={status ? 'heading-2' : 'heading-3'}>{label}</Text>
          <img style={{ width: 28, height: 28 }} src={cross} alt={'Error'} />
        </Box>
        {isFinished && <Text variant="subheading-1">{moment(date).format('DD/MM/YYYY')}</Text>}
        <Box sx={{ width: '100%' }}>
          <Typography sx={{ color: '#194052', fontSize: '14px', lineHeight: '18px', fontFamily: 'SF Compact', marginTop: '10px' }}>
            {description}
          </Typography>
        </Box>
      </Grid>
    )
  }
  return (
    <Container>
      {!isLoading && transaction ? (
        <Box>
          <Grid className="content" container>
            <Grid item sm={12}>
              <Text tabIndex={3} variant="message">
                Hello,
              </Text>
              <Text tabIndex={3} variant="message" sx={{ marginTop: '10px' }}>
                Here are the details of your Trade-In transaction.
              </Text>
            </Grid>
          </Grid>
          <StatusGrid container>
            {createStatusItem('transactionSubmitted')}
            {getLatestStatus(progress).key !== 'creditExpired' && getLatestStatus(progress).key !== 'creditCancelled' && (
              <>
                {createStatusItem('deviceLodged')} {createStatusItem('creditInProgress')}
              </>
            )}
            {renderLastStatus()}
          </StatusGrid>
          <Box tabIndex={5} sx={{ marginTop: '24px', padding: '0px 32px' }}>
            <Text variant="heading-1">Return Label</Text>
          </Box>
          <Box sx={{ paddingBottom: '48px' }}>
            <InfoBackground />
            <Info>{transaction && createInfo(transaction).map((item, index) => renderItem(item, index))}</Info>
          </Box>
        </Box>
      ) : (
        <Customer />
      )}
      <PageSnackbar
        visible={isErrorVisible}
        message={'Something went wrong. Try refreshing the page or try again later'}
        onClose={onErrorClose}
      />
    </Container>
  )
}
