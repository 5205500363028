import React from 'react'
import { Route, Routes } from 'react-router-dom'
import NotFound from 'pages/NotFound'
import Error from 'pages/Error'
import Transaction from 'pages/transaction/Transaction'
import ContactUs from 'pages/ContactUs'
import GetYourDeviceReady from 'pages/GetYourDeviceReady'

function Router() {
  return (
    <Routes>
      <Route path="transactions/:transactionId" element={<Transaction />} />
      <Route path="/contact-us" element={<ContactUs />} />
      <Route path="/get-your-device-ready" element={<GetYourDeviceReady />} />

      <Route path="/outage" element={<Error />} />
      <Route path="/*" element={<NotFound />} />
    </Routes>
  )
}

export default Router
