import * as React from 'react'
import { Alert, Snackbar, styled } from '@mui/material'

const MessageBox = styled(Snackbar)(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    top: 0,
    width: '100%',
  },
  [theme.breakpoints.down('sm')]: {
    top: 0,
    left: 0,
    right: 0,
  },
}))

interface Props {
  visible: boolean
  message: string
  onClose: () => void
}

const Table = ({ visible, message, onClose }: Props) => {
  // useEffect(() => {
  //   setTimeout(() => {
  //     dispatch(hideMessage())
  //   }, 5000)
  // }, [dispatch])

  return (
    <MessageBox anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={visible}>
      <Alert
        onClose={onClose}
        severity="error"
        sx={{
          width: '100%',
          height: '60px',
          borderRadius: 0,
          border: '1px solid #D6100E',
          alignItems: 'center',
        }}>
        {message}
      </Alert>
    </MessageBox>
  )
}

export default Table
