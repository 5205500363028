import { Box, Link, styled, Typography } from '@mui/material'
import * as React from 'react'
import Container from './common/Container'
import QR from 'assets/images/QR.png'
import Text from 'components/Text'

const StyledBox = styled(Box)(({ theme }) => ({
  padding: '15px 32px',
  marginTop: '24px',
  backgroundColor: '#F8F8F8',
}))

const Title = styled(Typography)(({ theme }) => ({
  fontSize: '22px',
  lineHeight: '29px',
  color: '#194052',
  margin: '10px 0px ',
  fontFamily: 'SF Compact bold',
}))

const Links = styled(Link)(({ theme }) => ({
  textDecoration: 'none',
}))

const ContactUs = () => {
  return (
    <Container headerTitle="We're here to help" page="ContactUs">
      <StyledBox>
        <Title>To download the Kingfisher Trade-In app</Title>
        <Text variant="contact-us">Please click the following links</Text>

        <Text variant="contact-us">
          <Links target="_blank" href="https://apps.apple.com/us/app/kingfisher-trade-in/id6443592671">
            <Text variant="contact-us" sx={{ display: 'inline', fontFamily: 'SF Compact bold' }}>
              Apple App Store
            </Text>
          </Links>
          <Text variant="contact-us" sx={{ display: 'inline', marginLeft: '5px' }}>
            or
          </Text>
          <Links href="https://play.google.com/store/apps/details?id=com.kingfisher_mx.telstra">
            <Text variant="contact-us" sx={{ display: 'inline', fontFamily: 'SF Compact bold', marginLeft: '5px' }}>
              Google Play Store
            </Text>
          </Links>
        </Text>

        <Text variant="contact-us" sx={{ marginTop: '30px' }}>
          Alternatively, please scan the below QR code.
        </Text>
        <img style={{ marginTop: 25, width: 148, height: 164 }} src={QR} alt={'QR'} />
      </StyledBox>
      <StyledBox>
        <Title>Support using the Trade-In app</Title>
        <Box sx={{ display: 'flex' }}>
          <Text variant="contact-us">
            Please contact Kingfisher on
            <Links href="tel:1300 919 010">
              <Text variant="contact-us" sx={{ display: 'inline', fontFamily: 'SF Compact bold', marginLeft: '5px' }}>
                1300 919 010
              </Text>
            </Links>
          </Text>
        </Box>
      </StyledBox>
      <StyledBox>
        <Title>Trade-In credit</Title>
        <Box sx={{ display: 'flex' }}>
          <Text variant="contact-us">
            If you have submitted a transaction and have an inquiry about your Trade-In credit, please contact Telstra at
            <Links target="_blank" href="https://www.telstra.com.au/contact-us">
              <Text variant="contact-us" sx={{ display: 'inline', fontFamily: 'SF Compact bold', marginLeft: '5px' }}>
                www.telstra.com.au/contact-us
              </Text>
            </Links>
          </Text>
        </Box>
      </StyledBox>
    </Container>
  )
}

export default ContactUs
