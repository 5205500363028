import axios from 'axios'
import CONFIG from '../../config'

export const getTransactionById = async (transactionId: string | undefined) => {
  const response = await axios({
    method: 'GET',
    url: CONFIG.apiEndpoint + '/transactions/' + transactionId,
    headers: {
      'Content-Type': 'application/json',
      'X-API-KEY': CONFIG.webAuthToken,
    },
  })
  console.log('response=>', response.data)
  return response.data
}
