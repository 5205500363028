import React, { ComponentProps } from 'react'
import { Typography } from '@mui/material'

type TextVariant = 'heading-1' | 'heading-2' | 'heading-3' | 'subheading-1' | 'message' | 'contact-us' | 'get-your-device-ready'

type TypographyProps = ComponentProps<typeof Typography>

type TextProps = { variant: TextVariant } & Omit<TypographyProps, 'variant'>

const Text = ({ variant, sx, children, ...rest }: TextProps) => {
  return (
    <Typography sx={{ ...getTextStyle(variant), ...sx }} {...rest}>
      {children}
    </Typography>
  )
}

const getTextStyle = (variant: any) => {
  switch (variant) {
    case 'heading-1':
      return {
        fontSize: 24,
        fontWeight: '790',
        color: '#194052',
        lineHeight: '40px',
        fontFamily: 'SF Compact Bold',
      }
    case 'heading-2':
      return {
        fontSize: 18,
        color: '#194052',
        fontFamily: 'SF Compact Bold',
        fontWeight: '790',
        lineHeight: '32px',
      }
    case 'heading-3':
      return {
        fontSize: 18,
        color: '#194052',
        fontWeight: '457',
        fontFamily: 'SF Compact',
        lineHeight: '32px',
      }
    case 'message':
      return {
        color: '#194052',
        fontSize: 18,
        lineHeight: '28px',
        fontWeight: '350',
        fontFamily: 'SF Compact',
      }
    case 'subheading-1':
      return {
        fontWeight: '457',
        fontFamily: 'SF Compact',
        fontSize: 18,
        lineHeight: '14px',
        color: '#194052',
      }
    case 'contact-us':
      return {
        fontSize: 18,
        lineHeight: '29px',
        color: '#194052',
        fontFamily: 'SF Compact',
      }
    case 'get-your-device-ready':
      return {
        fontSize: 18,
        lineHeight: '32px',
        color: '#194052',
        fontFamily: 'SF Compact',
      }
  }
}

export default Text
