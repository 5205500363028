// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { getAnalytics, logEvent } from 'firebase/analytics'
import CONFIG, { isProduction } from 'config/index'

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

// Initialize Firebase

const app = initializeApp(CONFIG.firebaseConfig)
const analytics = getAnalytics(app)

export const analyticsLogEvent = (event: string, data?: object) => {
  if (isProduction) {
    if (data) {
      logEvent(analytics, event, data)
    } else {
      logEvent(analytics, event)
    }
  }
}

export const analyticsLog = (pathname: string) => {
  // console.log('pathname', pathname)
  // console.log('pathname=>', pathname.split('/'))
  const params = pathname.split('/')
  if (params[1] === 'transactions') {
    analyticsLogEvent('Screen_Customer_Website_Landing', { transaction_id: params[2] })
  } else if (params[1] === 'contact-us') {
    analyticsLogEvent('Screen_Customer_Website_Contact_Us')
  } else if (params[1] === 'get-your-device-ready') {
    analyticsLogEvent('Screen_Customer_Website_Get_Your_Device_Ready')
  }
}
